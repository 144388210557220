import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Timesheet/Timesheet.css"
import SearchImg from "../../assets/images/searchIcon.png";
import { useSelector, useDispatch } from 'react-redux';
import { fetchClocking } from '../Redux/Action/clockingActions';

function Timesheet() {
    const dispatch = useDispatch();
    const clockingData = useSelector((state) => state.clocking);

    // Function to calculate total hours between check-in and check-out times
    const calculateTotalHours = (checkIn, checkOut) => {
        if (!checkIn || !checkOut) {
            return 0; // Return 0 if checkIn or checkOut is missing
        }
        const checkInTime = new Date(checkIn);
        const checkOutTime = new Date(checkOut);
        const differenceInMilliseconds = checkOutTime - checkInTime;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
        return differenceInHours;
    };

    // Function to calculate the total hours for all users
    const calculateTotalHoursForAllUsers = () => {
        return clockingData.clocking.reduce((total, clock) => {
            const userHours = calculateTotalHours(clock.clockInTime, clock.clockOutTime);
            return total + userHours;
        }, 0).toFixed(2); // Sum and format to 2 decimal places
    };

    // Function to calculate the total hours worked
    const calculateTotalHoursWorked = () => {
        return clockingData.clocking.reduce((total, clock) => {
            if (clock.clockOutTime) { // Only include users who have clocked out
                return total + clock.totalHours;
            }
            return total;
        }, 0).toFixed(2); // Sum and format to 2 decimal places
    };

    const formatTime = (timeString) => {
        if (!timeString) return ''; // Handle missing time
        const date = new Date(timeString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle missing date
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    };
    useEffect(() => {
        dispatch(fetchClocking());
    }, [dispatch]);

    if (clockingData.loading) {
        return <div>Loading...</div>;
    }

    if (clockingData.error) {
        return <div>Error: {clockingData.error}</div>;
    }

    const totalHours = calculateTotalHoursForAllUsers(); // Get the total hours for all users
    const totalHoursWorked = calculateTotalHoursWorked(); // Get the total hours worked for all users

    return (
        <div>
            <div className='clockHeader'>
                <div className="clockHeaderInput">
                    <input
                        type="search"
                        id="search"
                        name="search"
                        placeholder="Search Employee"
                    />
                    <img src={SearchImg} alt="searchImg" width={30} />
                </div>
            </div>

            <div className="timeSheetHeader">
                <div className="timeSheetBox">
                    <h2>TOTAL HOURS</h2>
                    <h6>{totalHours}</h6>
                </div>
                <div className="timeSheetBox">
                    <h2>TOTAL HOURS WORKED</h2>
                    <h6>{totalHoursWorked}</h6>
                </div>
                <div className="timeSheetBox">
                    <h2>OVERTIME</h2>
                    <h6>2</h6>
                </div>

            </div>

            <div className="timeSheetTable">
                <table>
                    <thead>
                        <tr>
                            <th>EMPLOYEE</th>
                            <th>EMPLOYEE ID</th>
                            <th>SHIFT</th>
                            <th>HOURS WORK</th>
                            {/* <th>BREAK</th> */}
                            {/* <th>OVERTIME</th> */}
                            <th>TOTAL HOURS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clockingData.clocking.map((clock) => (
                            <tr>
                                <td>John Doe</td>
                                <td>{clock.userId}</td>
                                <td>Day Shift</td>
                                <td>{clock.totalHours}</td>
                                {/* <td>1h</td> */}
                                {/* <td>-</td> */}
                                <td>{clock.totalHours}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Timesheet;