// src/components/NotFound.js
import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div style={{ textAlign: "center", padding: "250px" }}>
      <h1>404 - Page Not Found</h1>
      {/* <p>The page you are looking for does not exist.</p>
      <Link to="/">Go back to the Home Page</Link> */}
    </div>
  );
}

export default NotFound;
