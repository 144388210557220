import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Employee from "./components/Employee/Employee";
import EmployeeShow from "./components/Employee/EmployeeShow";
import EmployeeEdit from "./components/Employee/EmployeeEdit";
import Clocking from "./components/Clocking/Clocking";
import LinkedPlatform from "./components/LinkedPlatforms/LinkedPlatform";
import Timesheet from "./components/Timesheet/Timesheet";
import { Login } from "./components/auth/Login";
import { Register } from "./components/auth/Register";
import { EmployerProvider } from "./context/EmployerContext";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./components/NotFound";

function App() {
  return (
    <EmployerProvider>
      <Router>
        <Routes>
          <Route index path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
            <Route path="/dashboard/employeeShow" element={<ProtectedRoute><EmployeeShow /></ProtectedRoute>} />
            <Route path="/dashboard/employee" element={<ProtectedRoute><Employee /></ProtectedRoute>} />
            <Route path="/dashboard/employeeEdit/:id" element={<ProtectedRoute><EmployeeEdit /></ProtectedRoute>} />
            <Route path="/dashboard/clocking" element={<ProtectedRoute><Clocking /></ProtectedRoute>} />
            <Route path="/dashboard/LinkedPlatform" element={<ProtectedRoute><LinkedPlatform /></ProtectedRoute>} />
            <Route path="/dashboard/Timesheet" element={<ProtectedRoute><Timesheet /></ProtectedRoute>} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </EmployerProvider>
  );
}

export default App;
