import React, { useState } from "react";
import Logo from "../../assets/images/logo.avif";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Register = () => {
	const [formData, setFormData] = useState({
	  name: "",
	  surname: "",
	  email: "",
	  phone: "",
	  password: "",
	});
	const navigate = useNavigate();
  
	const baseURL = process.env.REACT_APP_API_BASE_URL;

	const handleChange = (e) => {
	  const { name, value } = e.target;
	  setFormData({ ...formData, [name]: value });
	};
  
	const handleSubmit = async (e) => {
	  e.preventDefault();
	  try {
		const response = await axios.post(
		  `${baseURL}employer/signup`, // Adjust the API endpoint accordingly
		  formData
		);
		console.log("Sign up successful!", response.data);
		// Redirect to the home page after successful sign up
		navigate("/");
	  } catch (error) {
		console.error("Sign up failed:", error);
		// You can handle errors here, such as displaying a message
	  }
	};
  
	return (
		<div className="login">
			<main>
				<div className="container">
					<div className="inner">
						<img src={Logo} alt="Logo" />
						<div className="center">
							<p>Please complete with the relevent details</p>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="field">
								<label for="name">Name</label>
								<input
									id="name"
									name="name"
									value={formData.name}
									onChange={handleChange}
									type="text"
									required
								/>
							</div>
							<div className="field">
								<label for="surname">Surname</label>
								<input
									id="surnmae"
									name="surname"
									type="text"
									value={formData.surname}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="field">
								<label for="email">Email</label>
								<input
									id="email"
									name="email"
									type="email"
									value={formData.email}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="field">
								<label for="phone">Phone</label>
								<input
									id="phone"
									name="phone"
									value={formData.phone}
									onChange={handleChange}
									type="text"
									required
								/>
							</div>
							<div className="field">
								<label for="password">Password</label>
								<input
									id="password"
									name="password"
									type="password"
									value={formData.password}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="center">
								<button className="btn-gradient" type="submit">
									Register
								</button>
							</div>
						</form>
					</div>
				</div>
			</main>
		</div>
	);
};
