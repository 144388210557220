// src/redux/clocking/clockingActions.js
import axios from 'axios';
import {
  FETCH_CLOCKING_REQUEST,
  FETCH_CLOCKING_SUCCESS,
  FETCH_CLOCKING_FAILURE,
} from './clockingActionTypes';

export const fetchClockingRequest = () => ({
  type: FETCH_CLOCKING_REQUEST,
});

export const fetchClockingSuccess = (clocking) => ({
  type: FETCH_CLOCKING_SUCCESS,
  payload: clocking,
});

export const fetchClockingFailure = (error) => ({
  type: FETCH_CLOCKING_FAILURE,
  payload: error,
});
const baseURL = process.env.REACT_APP_API_BASE_URL;


export const fetchClocking = () => {
  return (dispatch) => {
    dispatch(fetchClockingRequest());
    axios
      .get(`${baseURL}clocking`)
      .then((response) => {
        const clocking = response.data;
        dispatch(fetchClockingSuccess(clocking));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchClockingFailure(errorMsg));
      });
  };
};
