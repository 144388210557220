import React from "react";
import { useContext } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
// import Logo from "../../assets/images/Logo.avif";
import Logo from "../../assets/images/Logo2.png";
import UserProfile from "../../assets/images/user.avif";
import LogoIcon from "../../assets/images/deco.avif";
import { EmployerContext } from "../../context/EmployerContext"; // Import context


const Dashboard = () => {
	const { logout } = useContext(EmployerContext); // Get logout function from context
	const navigate = useNavigate();
  
	// Handle user logout
	const handleLogout = () => {
	  logout(); // Clear session data using the logout function
	  navigate("/"); // Redirect to the login page or home page
	};

    return (
        <div className="dashboard">
            <nav>
                <div className="container">
                    <input type="checkbox" id="nav" />
                    <div className="inner">
                        <div className="logo">
                            <div className="logo-inner">
                                <img src={Logo} alt="Logo" />
                            </div>
                        </div>
                        <div className="nav-list">
                            <ul>
                                <li>
                                    <Link to="/dashboard/overview">Overview</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/employeeShow">Employees</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/clocking">Clocking</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/reports">Reports</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/timesheet">Timesheets</Link>
                                </li>
                            </ul>
                            <hr />
                            <ul>
                                <li>
                                    <Link to="/dashboard/account">My Account</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/linkedPlatform">Linked Platforms</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/settings">Settings</Link>
                                </li>
                                <li>
                                    <Link to="/dashboard/help">Help</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="logout">
                            <div className="logout-inner">
                                <div className="user">
                                    <div>
                                        <img src={UserProfile} alt="UserProfile" />
										<div onClick={handleLogout} style={{ cursor: "pointer" }}>
											Logout
										</div>
                                    </div>
                                    <img src={LogoIcon} alt="LogoIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <header>
                <div className="company">
                    <h2>Frankenstein Creations</h2>
                </div>
            </header>

            <main>
                <Outlet />
            </main>
        </div>
    );
};

export default Dashboard;
