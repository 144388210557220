import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { EmployerContext } from "../../context/EmployerContext";
import { fetchEmployees } from "../Redux/Action/employeeActions";
import "./Employee.css";

const EmployeeShow = () => {
  const { employer } = useContext(EmployerContext);
  const employerId = employer?._id;

  const { employees, loading, error } = useSelector((state) => state.employee);
  const dispatch = useDispatch();

  useEffect(() => {
    if (employerId) {
      dispatch(fetchEmployees(employerId));
    } else {
      console.error("Employer ID is not available");
    }
  }, [dispatch, employerId]);
  return (
    <div className="container">
      {/* Cards for Total Employees, Hours Worked, Payment Made */}
      <div className="cards">
        <div className="card">
          <div className="inner">
            <div className="heading center">
              <h2>Total Employees</h2>
            </div>
            <div className="total">{employees.length}</div>
          </div>
        </div>

        <div className="card">
          <div className="inner">
            <div className="heading center">
              <h2>Hours Worked</h2>
            </div>
            <div className="total">0</div> {/* Update this with real data */}
          </div>
        </div>

        <div className="card">
          <div className="inner">
            <div className="heading center">
              <h2>Payment Made</h2>
            </div>
            <div className="total">0</div> {/* Update this with real data */}
          </div>
        </div>
      </div>

      {/* Search and Add Employee */}
      <div className="search">
        <form className="search">
          <div className="field">
            <label htmlFor="search">Employee Name</label>
            <input type="search" id="search" name="search" placeholder="Search" />
          </div>
          <div className="field">
            <label htmlFor="status">Status</label>
            <select name="status" id="status">
              <option value="" disabled>
                Select your option
              </option>
              <option value="option-1">Option 1</option>
              <option value="option-2">Option 2</option>
            </select>
          </div>
          <input type="submit" value="🔎︎" />
        </form>

        {/* Add Employee Button */}
        <div>
          <Link to="/dashboard/employee">
            <input type="submit" value="Add Employee" />
          </Link>
        </div>
      </div>

      {/* Employee Cards */}
      <div className="cards employee">
        {employees.length > 0 ? (
          employees.map((employee) => (
            <div className="card" key={employee._id}>
              <div className="details">
                <h3>{employee.name}</h3>
                <div className="role">{employee.position}</div>
                <Link to={`/dashboard/employeeEdit/${employee._id}`}>
                  Edit Employee
                </Link>
              </div>
              <div className="inner">
                <div className="center">
                  <h4>Employee ID</h4>
                  <span>{employee.id_number}</span>
                </div>
                <div className="center">
                  <h4>Employee Industry Number</h4>
                  <span>{employee.industry_number}</span>
                </div>
                <div className="center">
                  <h4>Join date</h4>
                  <span>{employee.birthday}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No employees found.</div>
        )}
      </div>
    </div>
  );
};

export default EmployeeShow;
