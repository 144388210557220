import {
	ADD_EMPLOYEE_REQUEST,
	ADD_EMPLOYEE_SUCCESS,
	ADD_EMPLOYEE_FAILURE,
	FETCH_EMPLOYEES_REQUEST,
	FETCH_EMPLOYEES_SUCCESS,
	FETCH_EMPLOYEES_FAILURE,
	FETCH_EMPLOYEE_REQUEST,
	FETCH_EMPLOYEE_SUCCESS,
	FETCH_EMPLOYEE_FAILURE,
	UPDATE_EMPLOYEE_REQUEST,
	UPDATE_EMPLOYEE_SUCCESS,
	UPDATE_EMPLOYEE_FAILURE,
} from "./employeeActionTypes";

import axios from "axios";
// Action creators
export const addEmployeeRequest = () => ({
	type: ADD_EMPLOYEE_REQUEST,
});

export const addEmployeeSuccess = (data) => ({
	type: ADD_EMPLOYEE_SUCCESS,
	payload: data,
});

export const addEmployeeFailure = (error) => ({
	type: ADD_EMPLOYEE_FAILURE,
	payload: error,
});

// show employees
export const fetchEmployeesRequest = () => ({
	type: FETCH_EMPLOYEES_REQUEST,
});

export const fetchEmployeesSuccess = (employees) => ({
	type: FETCH_EMPLOYEES_SUCCESS,
	payload: employees,
});

export const fetchEmployeesFailure = (error) => ({
	type: FETCH_EMPLOYEES_FAILURE,
	payload: error,
});

export const fetchEmployeeRequest = () => ({
	type: FETCH_EMPLOYEE_REQUEST,
});
export const fetchEmployeeSuccess = (employee) => ({
	type: FETCH_EMPLOYEE_SUCCESS,
	payload: employee,
});
export const fetchEmployeeFailure = (error) => ({
	type: FETCH_EMPLOYEE_FAILURE,
	payload: error,
});

export const updateEmployeeRequest = () => ({ type: UPDATE_EMPLOYEE_REQUEST });
export const updateEmployeeSuccess = (employee) => ({
	type: UPDATE_EMPLOYEE_SUCCESS,
	payload: employee,
});
export const updateEmployeeFailure = (error) => ({
	type: UPDATE_EMPLOYEE_FAILURE,
	payload: error,
});
// update mployees


const baseURL = process.env.REACT_APP_API_BASE_URL;


// Thunk action creator
export const addEmployee = (employerId, formData) => async (dispatch) => {
	try {
		dispatch(addEmployeeRequest());
		const response = await axios.post(`${baseURL}employer/employees/add/${employerId}`, formData);

		dispatch(addEmployeeSuccess(response.data));
	} catch (error) {
		dispatch(addEmployeeFailure(error.message));
	}
};

export const fetchEmployees = (employerId) => async (dispatch) => {
	try {
		dispatch(fetchEmployeesRequest());

		const response = await axios.get(`${baseURL}employer/employees/${employerId}`);

		dispatch(fetchEmployeesSuccess(response.data));
	} catch (error) {
		dispatch(fetchEmployeesFailure(error.message));
	}
};


export const fetchEmployee = (id) => async (dispatch) => {
	dispatch(fetchEmployeeRequest());
	try {
		const response = await axios.get(`${baseURL}employer/employees/single/${id}`);
		dispatch(fetchEmployeeSuccess(response.data.employee));
	} catch (error) {
		dispatch(fetchEmployeeFailure(error.message));
	}
};

export const updateEmployee = (id, formData) => async (dispatch) => {
	dispatch(updateEmployeeRequest());
	try {
		const response = await axios.put(`${baseURL}employer/employees/single/${id}`, formData);
		dispatch(updateEmployeeSuccess(response.data.employee));
	} catch (error) {
		dispatch(updateEmployeeFailure(error.message));
	}
};
