import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk'; // Import Redux Thunk
import employeeReducer from '../Reducer/employeeReducer';
import linkedPlatformReducer from '../Reducer/linkedPlatformReducer';
import clockingReducer from '../Reducer/clockingReducer';

const rootReducer = combineReducers({
  employee: employeeReducer,
  linkedPlatform: linkedPlatformReducer,
  clocking: clockingReducer,

});

const store = createStore(
  rootReducer,
  applyMiddleware(thunk) // Apply Redux Thunk middleware
);

export default store;
