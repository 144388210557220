// Import action types from employeeActionTypes file
import {
	FETCH_EMPLOYEES_REQUEST,
	FETCH_EMPLOYEES_SUCCESS,
	FETCH_EMPLOYEES_FAILURE,
	ADD_EMPLOYEE_REQUEST,
	ADD_EMPLOYEE_SUCCESS,
	ADD_EMPLOYEE_FAILURE,
	FETCH_EMPLOYEE_REQUEST,
	FETCH_EMPLOYEE_SUCCESS,
	FETCH_EMPLOYEE_FAILURE,
	UPDATE_EMPLOYEE_REQUEST,
	UPDATE_EMPLOYEE_SUCCESS,
	UPDATE_EMPLOYEE_FAILURE,
} from "../Action/employeeActionTypes";

// Define the initial state for the employee reducer
const initialState = {
	employees: [], // Array to hold employee data
	employee: {},
	loading: false, // Boolean to indicate loading state
	error: null, // Variable to hold error messages
};

// Define the employee reducer function
const employeeReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_EMPLOYEE_REQUEST:
			return { ...state, loading: true };
		// Handle the success case of adding a new employee
		case ADD_EMPLOYEE_SUCCESS:
			return {
				...state, // Copy all properties of the current state
				employees: [...state.employees, action.payload], // Append new employee to the state
				loading: false, // Set loading to false as the operation is complete
			};
		// Handle the failure case of adding a new employee
		case ADD_EMPLOYEE_FAILURE:
			return {
				...state, // Copy all properties of the current state
				loading: false, // Set loading to false as the operation failed
				error: action.payload, // Set error with the received error message
			};
		case FETCH_EMPLOYEES_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case FETCH_EMPLOYEES_SUCCESS:
			return {
				...state,
				loading: false,
				employees: action.payload,
				error: null,
			};
		// case FETCH_EMPLOYEES_FAILURE:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		error: action.payload,
		// 	};

		// Handle the failure case of fetching employees
		case FETCH_EMPLOYEE_REQUEST:
		case UPDATE_EMPLOYEE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_EMPLOYEE_SUCCESS:
			return {
				...state,
				loading: false,
				employee: action.payload,
				error: "",
			};
		case FETCH_EMPLOYEE_FAILURE:
		case UPDATE_EMPLOYEE_FAILURE:
			return {
				...state,
				loading: false,
				employee: {},
				error: action.payload,
			};
		case UPDATE_EMPLOYEE_SUCCESS:
			return {
				...state,
				loading: false,
				employee: action.payload,
				error: "",
			};
		case "UPDATE_EMPLOYEE_FIELD":
			return {
				...state,
				employee: {
					...state.employee,
					[action.payload.name]: action.payload.value,
				},
			};
		default:
			return state;
	}
};

// Export the employee reducer as the default export
export default employeeReducer;
