import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchEmployee, updateEmployee } from "../Redux/Action/employeeActions";
import ProfileImg from "../../assets/images/ProfileImg.png";
import "../Employee/Employee.css";

const EmployeeEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const { loading, employee, error } = useSelector((state) => state.employee);

    useEffect(() => {
        dispatch(fetchEmployee(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch({ type: 'UPDATE_EMPLOYEE_FIELD', payload: { name, value } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(updateEmployee(id, employee));
        navigate("/dashboard/employeeShow");
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="container">
            <div className="personProfile">
                <img src={ProfileImg} alt="Profile" width={120} />
                <h2>
                    {employee.name} {employee.surname}
                </h2>
                <div className="role">
                    <label>{employee.position}</label>
                </div>
                <div className="employeeSection">
                    <label>
                        Employee ID: <span>{employee.emp_id}</span>
                    </label>
                    <label>
                        Department: <span>{employee.department}</span>
                    </label>
                    <label className="lastBorder">
                        Industry Number: <span>{employee.industry_number}</span>
                    </label>
                </div>
            </div>
            <div className="cards settings">
                <div className="inner">
                    <form onSubmit={handleSubmit}>
                        <fieldset>
                            <legend>Employee Personal Details</legend>
                            <div className="field">
                                <label htmlFor="email">Email*</label>
                                <input
                                    id="email"
                                    name="email"
                                    value={employee.email || ''}
                                    onChange={handleChange}
                                    type="email"
                                    required
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="phone">Phone*</label>
                                <input
                                    id="phone"
                                    name="phone"
                                    value={employee.phone || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="id_number">ID Number*</label>
                                <input
                                    id="id_number"
                                    name="id_number"
                                    value={employee.id_number || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="tax_number">Tax Number*</label>
                                <input
                                    id="tax_number"
                                    name="tax_number"
                                    value={employee.tax_number || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="field fill">
                                <label htmlFor="address">Address*</label>
                                <input
                                    id="address"
                                    name="address"
                                    value={employee.address || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Employee Banking Details</legend>
                            <div className="field">
                                <label htmlFor="bank">Bank*</label>
                                <select
                                    name="bank"
                                    id="bank"
                                    value={employee.bank || ''}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Select your option
                                    </option>
                                    <option value="absa">ABSA</option>
                                    <option value="nedbank">NEDBANK</option>
                                    <option value="fnb">FNB</option>
                                </select>
                            </div>
                            <div className="field">
                                <label htmlFor="acc_number">Account Number*</label>
                                <input
                                    id="acc_number"
                                    value={employee.acc_number || ''}
                                    onChange={handleChange}
                                    name="acc_number"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="acc_type">Account Type*</label>
                                <select
                                    name="acc_type"
                                    id="acc-type"
                                    value={employee.acc_type || ''}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected>
                                        Select your option
                                    </option>
                                    <option value="check">Check</option>
                                    <option value="savings">Savings</option>
                                </select>
                            </div>
                            <div className="field">
                                <label htmlFor="branch">Branch Number*</label>
                                <input
                                    id="branch"
                                    name="branch"
                                    value={employee.branch || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="holder_name">Account Holder Name*</label>
                                <input
                                    id="holder_name"
                                    name="holder_name"
                                    value={employee.holder_name || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Employee Login Details</legend>
                            <div className="field">
                                <label htmlFor="pay_rate">Pay Rate*</label>
                                <input
                                    id="pay_rate"
                                    name="pay_rate"
                                    value={employee.pay_rate || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="pay_frequency">Pay Frequency*</label>
                                <input
                                    id="pay_frequency"
                                    name="pay_frequency"
                                    value={employee.pay_frequency || ''}
                                    onChange={handleChange}
                                    type="text"
                                    required
                                />
                            </div>
                        </fieldset>
                        <input type="submit" value="Update Employee" />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeEdit;
