import React, { useState, useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployerContext } from '../../context/EmployerContext'; // Import EmployerContext
import { useDispatch, useSelector } from 'react-redux';
import { addEmployee } from '../Redux/Action/employeeActions';


const Employee = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    id_number: "",
    tax_number: "",
    address: "",
    bank: "",
    acc_number: "",
    acc_type: "",
    branch: "",
    holder_name: "",
    pay_rate: "",
    pay_frequency: "",
    position: "",
    birthday: "",
    emp_id: "",
    status: "",
    department: "",
    industry_number: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch(); // Redux dispatch
  const { loading, success, error } = useSelector((state) => state.employee); // Access Redux state

  const { employer } = useContext(EmployerContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!employer || !employer._id) {
      console.error("Employer ID is required");
      return;
    }
  
    console.log('FormData:', formData);
    console.log('Employer ID:', employer._id);
  
    // Dispatch action to add employee
    dispatch(addEmployee(employer._id, formData));
  
    // Navigate to the dashboard immediately after form submission
    navigate('/dashboard/employeeShow');
  };

  // useEffect(() => {
  //   if (success) {
  //     navigate('/dashboard/employeeShow');
  //   }
  // }, [success, navigate]);
  return (
    <div className="container">
      <div className="cards settings">
        <form onSubmit={handleSubmit}>
          <div className="SubmitBtn">
            <h2>ADD EMPLOYEE</h2>
            <input type="submit" value="Add Employee" />
          </div>
          <fieldset>
            <legend>Employee Personal Details</legend>
            <div className="field">
              <label htmlFor="name">First Name*</label>
              <input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="surname">Last Name*</label>
              <input
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="email">Email*</label>
              <input
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="phone">Phone*</label>
              <input
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="id_number">ID Number*</label>
              <input
                id="id_number"
                name="id_number"
                value={formData.id_number}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="tax_number">Tax Number*</label>
              <input
                id="tax_number"
                name="tax_number"
                value={formData.tax_number}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field fill">
              <label htmlFor="address">Address*</label>
              <input
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Employee Banking Details</legend>
            <div className="field">
              <label htmlFor="bank">Bank*</label>
              <select
                name="bank"
                id="bank"
                value={formData.bank}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select your option
                </option>
                <option value="absa">ABSA</option>
                <option value="nedbank">NEDBANK</option>
                <option value="fnb">FNB</option>
              </select>
            </div>
            <div className="field">
              <label htmlFor="acc_number">Account Number*</label>
              <input
                id="acc_number"
                value={formData.acc_number}
                onChange={handleChange}
                name="acc_number"
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="acc_type">Account Type*</label>
              <select
                name="acc_type"
                id="acc-type"
                value={formData.acc_type}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                <option value="check">Check</option>
                <option value="savings">Savings</option>
              </select>
            </div>
            <div className="field">
              <label htmlFor="branch">Branch Number*</label>
              <input
                id="branch"
                name="branch"
                value={formData.branch}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="holder_name">Account Holder Name</label>
              <input
                id="holder_name"
                name="holder_name"
                value={formData.holder_name}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Payment Information</legend>
            <div className="field">
              <label htmlFor="pay_rate">Pay Rate*</label>
              <input
                id="pay_rate"
                name="pay_rate"
                value={formData.pay_rate}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="pay_frequency">Pay Frequency*</label>
              <input
                id="pay_frequency"
                name="pay_frequency"
                type="text"
                value={formData.pay_frequency}
                onChange={handleChange}
                required
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Employment Information</legend>
            <div className="field">
              <label htmlFor="position">Position*</label>
              <input
                id="position"
                name="position"
                value={formData.position}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="birthday">Start Date*</label>
              <input
                type="date"
                id="birthday"
                name="birthday"
                value={formData.birthday}
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <label htmlFor="emp_id">Employee ID*</label>
              <input
                id="emp_id"
                name="emp_id"
                type="text"
                value={formData.emp_id}
                onChange={handleChange}
                required
              />
            </div>
            <div className="field">
              <label htmlFor="status">Employee Status*</label>
              <input
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="department">Department*</label>
              <select
                name="department"
                id="department"
                value={formData.department}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                <option value="option-1">Option 1</option>
                <option value="option-2">Option 2</option>
              </select>
            </div>
            <div className="field">
              <label htmlFor="industry_number">Industry Number*</label>
              <input
                id="industry_number"
                name="industry_number"
                type="text"
                value={formData.industry_number}
                onChange={handleChange}
                required
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default Employee;
