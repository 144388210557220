import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.avif";
import axios from "axios";
import './Auth.css';
import { EmployerContext } from "../../context/EmployerContext";

export const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useContext(EmployerContext);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}employer/login`, formData);
      const { accessToken, employer } = response.data;

      // Save employer data and token using context
      login(employer, rememberMe, accessToken);
      // Redirect to dashboard
      navigate("/dashboard/employeeShow");
      window.location.reload();
    } catch (error) {
      setErrorMessage("Login failed. Please check your credentials.");
    }
  };


  return (
    <div className="login">
      <main>
        <div className="container">
          <div className="inner">
            <img className="logo" src={Logo} alt="Logo" />
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              {errorMessage && (
                <p className="error">{errorMessage}</p>
              )}
              <div className="checkboxWrapper">
				<div className="RadioBtn">
                <input
                  id="rememberCheckbox"
                  className="checkBox"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleCheckboxChange}
                />
				</div>
				<div>
                	<label htmlFor="rememberCheckbox">Remember me</label>
				</div>
              </div>

              <div className="center">
                <button className="btn-gradient" type="submit">
                  SIGN IN
                </button>
                <div className="register">
                  <p>Don't have an account yet?</p>
                  <Link to="/register" className="registerBtn">Register</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};
