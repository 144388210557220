import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { EmployerContext } from "../context/EmployerContext";

const ProtectedRoute = ({ children }) => {
  const { employer } = useContext(EmployerContext);

  // Check if the employer is logged in
  if (!employer) {
    return <Navigate to="/dashboard/employeeShow" />;
  }

  return children;
};

export default ProtectedRoute;
