import axios from 'axios';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VIPIN_UPDATE_REQUEST,
  VIPIN_UPDATE_SUCCESS,
  VIPIN_UPDATE_FAILURE,
} from './employeeActionTypes';

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const login = (formData) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const response = await axios.post(`${baseURL}user/login`, formData);
    const userData = response.data;

    // Handle digiledger_linked value
    if (userData.digiledger_linked === 1 || userData.digiledger_linked === 2) {
      localStorage.setItem('loggedInUser', JSON.stringify(userData));
    } else {
      localStorage.removeItem('loggedInUser');
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: userData,
    });
    // dispatch(updateVipinUser(userData));
    return userData;
  } catch (error) {
    console.error('Error during login:', error);
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response?.data?.message || 'An error occurred during login.',
    });
    throw error;
  }
};

export const linkDigiLedgerAccount = (formData) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const response = await axios.post(`${baseURL}user/link-digiledger`, formData);
    const userData = response.data;

    // Handle digiledger_linked value
    if (userData.digiledger_linked === 1 || userData.digiledger_linked === 2) {
      localStorage.setItem('loggedInUser', JSON.stringify(userData));
    } else {
      localStorage.removeItem('loggedInUser');
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: userData,
    });
    dispatch(updateVipinUser(userData));
    return userData;
  } catch (error) {
    console.error('Error during linking DigiLedger account:', error);
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response?.data?.message || 'An error occurred during linking DigiLedger account.',
    });
    throw error;
  }
};

export const updateVipinUser = (data) => async (dispatch) => {
  dispatch({ type: VIPIN_UPDATE_REQUEST });

  try {
    const response = await axios.put(`${baseURL}employer/vipin/update`, data);
    dispatch({
      type: VIPIN_UPDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error('Error updating VIPIN user:', error);
    dispatch({
      type: VIPIN_UPDATE_FAILURE,
      payload: error.response?.data?.message || 'An error occurred during VIPIN user update.',
    });
  }
};

