import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../LinkedPlatforms/LinkedPlatform.css";
import UserImg from "../../assets/images/user.avif";
import { linkDigiLedgerAccount, updateVipinUser } from "../Redux/Action/linkedPlatformAction";

const LinkedPlatform = () => {
	const [showPopup, setShowPopup] = useState(false);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const dispatch = useDispatch();
	const { loggedInUser, vipinUser, loading, error } = useSelector(
		(state) => state.linkedPlatform
	);

	useEffect(() => {
		const user = localStorage.getItem("loggedInUser");
		if (user) {
			dispatch({ type: "LOGIN_SUCCESS", payload: JSON.parse(user) });
		}
	}, [dispatch]);

	useEffect(() => {
		let employerUser = localStorage.getItem("employer");
		if(employerUser){
		   employerUser = JSON.parse(employerUser);
		}
		debugger
		if (loggedInUser) {
			console.log("Logged in user found:", loggedInUser);
			const data = {
				
				id: employerUser?._id,
				vipin_token: loggedInUser?.accessToken,
				vipin_username: loggedInUser?.user?.username,
			};
			dispatch(updateVipinUser(data));
		}
	}, [loggedInUser, dispatch]);

	const togglePopup = () => {
		setShowPopup(!showPopup);
	};

	const closePopup = () => {
		setShowPopup(false);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(linkDigiLedgerAccount(formData));
		setShowPopup(false);
	};

	console.log("vipinUser:", vipinUser);
	console.log("loggedInUser:", loggedInUser);

	return (
		<div className="container">
			<div className="accordion">
				<div className="accordion-item">
					<input
						id="accordion-trigger-1"
						className="accordion-trigger-input"
						type="checkbox"
					></input>
					<label className="accordion-trigger" htmlFor="accordion-trigger-1">
						VIPIN
					</label>
					<section className="accordion-animation-wrapper">
						<div className="accordion-animation">
							<div className="accordion-transform-wrapper">
								<div className="accordion-content">
									<div>
										<p>My Vipin Account</p>
										<button onClick={togglePopup}>+Add Account</button>
									</div>
									{loggedInUser && (
										<div>
											<img src={UserImg} alt="user-image" />
											<span>
												{loggedInUser.user?.username || loggedInUser.username}
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			{showPopup && (
				<div className="popup">
					<div className="popup-content">
						<span className="close" onClick={closePopup}>
							&times;
						</span>
						<h2>Add Account</h2>
						<div className="popup-form">
							<form onSubmit={handleSubmit}>
								<div className="field">
									<label htmlFor="email">Email</label>
									<input
										id="email"
										name="email"
										type="email"
										required
										value={formData.email}
										onChange={handleChange}
									/>
								</div>
								<div className="field">
									<label htmlFor="password">Password</label>
									<input
										id="password"
										name="password"
										type="password"
										value={formData.password}
										onChange={handleChange}
										required
									/>
								</div>
								<button type="submit" disabled={loading}>
									Connect
								</button>
							</form>
							{error && <p className="error">{error}</p>}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default LinkedPlatform;
