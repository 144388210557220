import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Clocking/Clocking.css"
import EditImg from "../../assets/images/editIcon.png";
import DelImg from "../../assets/images/delIcon.png";
import SearchImg from "../../assets/images/searchIcon.png";
import { useSelector, useDispatch } from 'react-redux';
import { fetchClocking } from '../Redux/Action/clockingActions';
function Clocking() {
  const dispatch = useDispatch();
  const clockingData = useSelector((state) => state.clocking);
  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  };
  useEffect(() => {
    dispatch(fetchClocking());
  }, [dispatch]);

  if (clockingData.loading) {
    return <div>Loading...</div>;
  }

  if (clockingData.error) {
    return <div>Error: {clockingData.error}</div>;
  }
  return (
    <div>
      <div className='clockHeader'>
        <div className="clockHeaderInput">
          <input
            type="search"
            id="search"
            name="search"
            placeholder="Search Employee"
          />
          <img src={SearchImg} alt="searchImg" width={30} />
        </div>
        <div>
          <Link to="#" className="clockHeaderlink">
            <input type="submit" value="+ Add new" />
          </Link>
        </div>
      </div>

      <div className="clockTabel">
        <table>
          <thead>
            <tr className="borderBottom borderleft">
              <th className="borderRight"></th>
              <th className="borderRight">Employee</th>
              <th className="borderRight">Shift</th>
              <th className="borderRight">Period</th>
              <th className="borderRight">Time In</th>
              <th className="borderRight">Time Out</th>
            </tr>
          </thead>
          <tbody>
            {clockingData.clocking.map((clock) => (
              <tr>
                <td className="borderRight" id="tdFirstColum">{clock?.logDetails}</td>
                <td className="borderRight">John Doe <br /><span>{clock.userId}</span></td>
                <td className="borderRight"> <label id="clockShift">Day Shift</label></td>
                <td className="borderRight">{clock.clockInTime ? formatDate(clock.clockInTime) : ""} - {clock.clockOutTime ? formatDate(clock.clockOutTime) : ""}</td>
                <td className="borderRight"> <label id="timeIn">{clock.clockInTime ? formatTime(clock.clockInTime) : "-"}</label></td>
                <td className="borderRight"> <label id="timeOut">{clock.clockOutTime ? formatTime(clock.clockOutTime) : "-"}</label></td>
                <td>
                  {/* <div className="actionBtns">
                  <img src={EditImg} alt="Profile" width={20} />
                  <img src={DelImg} alt="Profile" width={20} />
                </div> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="cardsSection">
        <div className="cards">
          <div className="card">
            <h3>Late Arrivals</h3>
          </div>
          <div className="inner">
            <div>
              <p>John Doe <br />
                <span>D25678</span>
              </p>
            </div>
            <div>
              <p>2024-01-28</p>
            </div>
          </div>
        </div>
        <div className="cards">
          <div className="card">
            <h3>Absent</h3>
          </div>
          <div className="inner">
            <div>
              <p>John Doe <br />
                <span>D25678</span>
              </p>
            </div>
            <div>
              <p>2024-01-28</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clocking