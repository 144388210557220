// src/redux/clocking/clockingReducer.js
import {
    FETCH_CLOCKING_REQUEST,
    FETCH_CLOCKING_SUCCESS,
    FETCH_CLOCKING_FAILURE,
  } from '../Action/clockingActionTypes';
  
  const initialState = {
    loading: false,
    clocking: [],
    error: '',
  };
  
  const clockingReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CLOCKING_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_CLOCKING_SUCCESS:
        return {
          loading: false,
          clocking: action.payload,
          error: '',
        };
      case FETCH_CLOCKING_FAILURE:
        return {
          loading: false,
          clocking: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default clockingReducer;
  