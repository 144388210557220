import React, { createContext, useState, useEffect } from "react";

export const EmployerContext = createContext();

export const EmployerProvider = ({ children }) => {
  const [employer, setEmployer] = useState(null);

  useEffect(() => {
    const checkTokenValidity = () => {
      const storedEmployer = localStorage.getItem("employer") || sessionStorage.getItem("employer");
      const storedTokenExpiration = localStorage.getItem("tokenExpiration") || sessionStorage.getItem("tokenExpiration");
      const currentTime = new Date().getTime();

      if (storedEmployer && storedTokenExpiration && currentTime < storedTokenExpiration) {
        setEmployer(JSON.parse(storedEmployer));
      } else {
        // Token is expired or no token found
        localStorage.removeItem("employer");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        sessionStorage.removeItem("employer");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("tokenExpiration");
        setEmployer(null);
      }
    };

    checkTokenValidity();
  }, []);

  const login = (employerData, rememberMe, token) => {
    const expiration = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

    if (rememberMe) {
      localStorage.setItem("employer", JSON.stringify(employerData));
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiration", expiration);
    } else {
      sessionStorage.setItem("employer", JSON.stringify(employerData));
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("tokenExpiration", expiration);
    }

    setEmployer(employerData);
  };

  const logout = () => {
    localStorage.removeItem("employer");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    sessionStorage.removeItem("employer");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("tokenExpiration");
    setEmployer(null);
  };

  return (
    <EmployerContext.Provider value={{ employer, login, logout }}>
      {children}
    </EmployerContext.Provider>
  );
};
