import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VIPIN_UPDATE_REQUEST,
  VIPIN_UPDATE_SUCCESS,
  VIPIN_UPDATE_FAILURE,
} from '../Action/employeeActionTypes';

const initialState = {
  loading: false,
  error: null,
  loggedInUser: null,
  vipinUser: null,
};

const linkedPlatformReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case VIPIN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedInUser: action.payload,
        error: null, // Clear any previous error on success
      };
    case VIPIN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        vipinUser: action.payload,
        error: null, // Clear any previous error on success
      };
    case LOGIN_FAILURE:
    case VIPIN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default linkedPlatformReducer;
